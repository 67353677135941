<template>
  <div
    class="hm-switch-group"
    :class="{
      'hm-switch-group--disabled': disabled,
    }"
  >
    <label
      class="hm-switch"
      :class="switchClasses"
      :for="name"
    >
      <span class="hm-switch-button"></span>
    </label>
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @click="handleChange"
      class="hm-switch-checkbox"
      :id="name"
    />
    <span :id="`error_${name}`">{{ errorMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: {
    type: null,
  },
  name: {
    type: String,
    default: () => `hm-switch`,
  },
  rules: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'brand',
  },
});

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { checked, handleChange, errorMessage } = useField(name, props.rules, {
  type: 'checkbox',
  checkedValue: true,
  uncheckedValue: false,
  syncVModel: true,
});

const switchClasses = computed(() => {
  return [
    `hm-switch--${props.color}`,
    checked?.value ? 'hm-switch--active' : '',
  ].join(' ');
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
$height: 22px;
$padding: 2px;

.hm-switch-group {
  display: inline-block;
  position: relative;
  padding: 0.5rem 0;
  &--disabled {
    opacity: 0.5;
    .hm-switch {
      cursor: default;
    }
  }
}

.hm-switch {
  display: flex;
  align-items: center;
  margin: 0;

  cursor: pointer;
  user-select: none;

  width: 44px;
  border-radius: 22px;

  background-color: $medium-gray;

  &--active {
    .hm-switch-button {
      transform: translateX(18px);
    }
  }

  &--active.hm-switch--brand {
    background-color: var(--brand-color, $coral);
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.hm-switch-button {
  display: flex;
  background-color: white;
  height: $height;
  width: $height;
  margin: 2px;
  border-radius: 50%;
  transform: translateX(0px);
  transition: transform 0.2s;
}

.hm-switch-checkbox {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>
